import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeyziiService } from 'src/app/wss/keyzii.service';
import { NotifyUser, SetAPIParams } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { apiParams, hostUrl, repUrl, siteEnabled } from '../../pages.selectors';
import { Clipboard } from '@angular/cdk/clipboard'
import { PagesService } from '../../pages.service';
import { WSSState } from 'src/app/wss/wss.model';
import { customerId, myId } from 'src/app/wss/wss.selectors';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['../links/links.component.scss', './api.component.scss']
})
export class ApiComponent {
  api_iframe_url: string = ""
  show_in: string = "browser"
  customer_id: string | undefined

  @Input() set save_it(what: string) {
    if (what.startsWith("api")) {
      this.saveIt()
    }
  }
  @ViewChild('api') api: ElementRef | undefined
  // @ViewChild('embed') embed: ElementRef | undefined
  // @ViewChild('embed_per') embed_per: ElementRef | undefined
  // @ViewChild('embed_f') embed_f: ElementRef | undefined
  // @ViewChild('embed_per_f') embed_per_f: ElementRef | undefined
  // @ViewChild('api_scr') api_scr: ElementRef | undefined


  @Output() enableSave: EventEmitter<boolean> = new EventEmitter();
  subscriptions: any[] = []
  rep_url: string | undefined
  script_url: string | undefined
  iframe_example: string | undefined
  site_enabled: boolean = false

  host_url: string | undefined
  my_id: string | undefined
  bShowHelp: boolean = false
  constructor(
    public pagesState: Store<PagesState>,
    private keyzii_service: KeyziiService,
    private clipboard: Clipboard,
    private page_service: PagesService,
    private wssState: Store<WSSState>,

  ) {
    this.subscriptions.push(this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
    }))
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.pagesState.select(siteEnabled).subscribe((site_enabled: boolean) => {
      this.site_enabled = site_enabled
      if (this.site_enabled) {
        this.makeButtons()
      }
    }))

    this.subscriptions.push(this.pagesState.select(apiParams).subscribe((params: any) => {
      if (params && params.api_iframe_url) {
        setTimeout(() => {
          this.api_iframe_url = params.api_iframe_url;
          if (params.show_in) {
            this.show_in = params.show_in
          }
        });
      }
    }))
    this.subscriptions.push(this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id
      this.makeURL()
    }))
    this.subscriptions.push(this.pagesState.select(repUrl).subscribe((url: string | undefined) => {
      this.rep_url = url
      this.makeURL()

    }))

  }
  makeURL() {
    const rep_url = "https://" + this.rep_url
    this.script_url = '<script src="' + rep_url + '/api/keyzii_api.js" host="' + rep_url + '" customer_id="' + this.customer_id + '">'

    this.iframe_example = '<iframe src="' + rep_url + '?customer_id="' + this.customer_id + '" width="100%" height="820px" allow="camera; microphone" frameborder="0"></iframe>'

  }
  ngAfterViewInit() {
    if (this.api) {
      this.api.nativeElement.innerHTML = this.page_service.makeCopyHTML("Copy Keyzii API Script")
    }
    this.makeButtons()
  }
  saveIt() {
    let params = {
      api_iframe_url: this.api_iframe_url,
      show_in: this.show_in
    }
    this.pagesState.dispatch(new SetAPIParams(params))
    this.keyzii_service.setAPIIParams(params)
    this.pagesState.dispatch(new NotifyUser("API parameters saved"))
    this.enableSave.emit(false)
  }
  changeURL($event: any) {
    this.enableSave.emit(true);
  }
  showIn(where: string) {
    this.show_in = where
    this.enableSave.emit(true);
  }

  copy($event: any) {
    if (this.script_url) {
      this.clipboard.copy(this.script_url);
      this.pagesState.dispatch(new NotifyUser("keyzii API embed code copied"))
    }
  }
  copyFrame($event: any) {
    if (this.script_url) {
      if (this.iframe_example) {
        this.clipboard.copy(this.iframe_example);
        this.pagesState.dispatch(new NotifyUser("keyzii iFrame code copied"))
      }
    }
  }
  makeButtons() {

    // if (this.site_enabled && this.embed && this.embed_per) {
    //   this.embed.nativeElement.innerHTML = this.page_service.makeCopyHTML("Copy Script")
    //   this.embed_per.nativeElement.innerHTML = this.page_service.makeCopyHTML("Copy Script")
    // }
    // if (this.site_enabled && this.embed_f && this.embed_per_f && this.api_scr) {
    //   this.embed_f.nativeElement.innerHTML = this.page_service.makeCopyHTML("Copy iFrame")
    //   this.embed_per_f.nativeElement.innerHTML = this.page_service.makeCopyHTML("Copy iFrame")
    //   this.api_scr.nativeElement.innerHTML = this.page_service.makeCopyHTML("Copy API Script")
    //
    // }
  }
  embedCia() {
    // let protocol = "https://"
    // if (this.host_url && this.host_url.indexOf("local") >= 0) {
    //   protocol = "http://"
    // }
    // let script_url = protocol + this.host_url + "/assets/api/site/kzmLoader.js"
    let script_url = "assets/api/site/kzmLoader.js"
    let script_html = '<script src="' + script_url + '" id="kz" customer_id="' + this.customer_id + '"> </script>'
    this.clipboard.copy(script_html);
    this.pagesState.dispatch(new NotifyUser("Company script copied"))


  }
  embedPersonal() {
    // let protocol = "https://"
    // if (this.host_url && this.host_url.indexOf("local") >= 0) {
    //   protocol = "http://"
    // }
    // let script_url = protocol + this.host_url + "/assets/api/site/kzmLoader.js"

    let script_url = "assets/api/site/kzmLoader.js"
    let script_html = '<script src="' + script_url + '" id="kz" rep_id="' + this.my_id + '"> </script>'
    this.clipboard.copy(script_html);
    this.pagesState.dispatch(new NotifyUser("Personal script copied"))
  }
  copyCiaiFrame() {
    const rep_url = "https://" + this.rep_url
    let iframe_example = '<iframe src="' + rep_url + '?customer_id="' + this.customer_id + '" width="100%" height="820px" allow="camera; microphone" frameborder="0"></iframe>'
    this.clipboard.copy(iframe_example);
    this.pagesState.dispatch(new NotifyUser("Company iFrame copied"))
  }
  copyPeriFrame() {
    const rep_url = "https://" + this.rep_url
    let iframe_example = '<iframe src="' + rep_url + '?rep_id="' + this.my_id + '" width="100%" height="820px" allow="camera; microphone" frameborder="0"></iframe>'
    this.clipboard.copy(iframe_example);
    this.pagesState.dispatch(new NotifyUser("Personal iFrame copied"))
  }
  openCoHelp() { this.bShowHelp = true }
}
