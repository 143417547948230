import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';
import { PayService } from 'src/app/kpay/pay.service';
import { NotifyError, ShowCC } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { accOverdue, convergeAcc, hasAPI, isDealer, merchantId, myRoll } from '../../pages.selectors';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  hdr_left: number = 0
  mv_scr_left: number = 0
  tab: string = "contact"
  roll: string = "admin"
  subscriptions: any[] = []
  title: string = "Profile"
  bShowSave: boolean = false
  save_back: string = "#ECECEC"
  save_color: string = "#151515"
  save_it: string = ""
  bHasAPI: boolean = true;
  //users
  filter_name: string | undefined
  filter_type: string | undefined
  search: string = ""
  bEditMechantId: boolean = true;
  merchant_id: string = ""
  user_types = [
    'Admin',
    // 'Manager',
    'Agent']

  tabs: any[] = []
  orig_tabs: any[] = [{
    title: "Profile",
    id: "contact",
    selected: false
  },
  {
    title: "Calendar",
    id: "calendar",
    selected: false
  },
  {
    title: "Links",
    id: "links",
    selected: false
  },
  {
    title: "Users",
    id: "users",
    selected: false
  },
  {
    title: "Customization",
    id: "custom",
    selected: false
  },
  {
    title: "Billing",
    id: "billing",
    selected: false
  },
  {
    title: "Advanced",
    id: "api",
    selected: false
  },
  {
    title: "Payments",
    id: "payments",
    selected: false
  },
    // {
    //   title: "Reseller",
    //   id: "dealer",
    //   selected: false
    // }
  ]
  show_scroll: boolean = false
  start_at_tab_index: number = 0
  last_tab_index: number = 5
  converge_acc: string | undefined
  // bShowBillingSearch: boolean = true
  bAccOverdue: boolean = false
  constructor(
    public pagesState: Store<PagesState>,
    public pay_service: PayService,
    public canvasStore: Store<CanvasState>,


  ) {
    console.log("profile")

    this.subscriptions.push(this.pagesState.select(isDealer).subscribe((is_dealer: boolean) => {
      if (is_dealer) {
        let orig_tabs = Object.assign([], this.orig_tabs)
        orig_tabs.push(
          {
            title: "Reseller",
            id: "dealer",
            selected: false
          })
        this.orig_tabs = orig_tabs
      }
    }))
    this.subscriptions.push(this.pagesState.select(accOverdue).subscribe((overdue: boolean) => {
      this.bAccOverdue = overdue
    }))
    this.subscriptions.push(this.pagesState.select(convergeAcc).subscribe((converge_acc: string | undefined) => {
      this.converge_acc = converge_acc

    }))
    this.subscriptions.push(this.pagesState.select(merchantId).subscribe((merchant_id: string | undefined) => {
      if (merchant_id) {
        this.merchant_id = merchant_id
        this.bEditMechantId = false
      }
    }))
    this.subscriptions.push(this.pagesState.select(myRoll).subscribe((my_roll: string) => {
      setTimeout(() => {
        this.roll = my_roll
        this.initiTabs()
      });
    }))
    this.subscriptions.push(this.pagesState.select(hasAPI).subscribe((bHasAPI: boolean) => {
      setTimeout(() => this.bHasAPI = bHasAPI);
    }))

    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      if (opt.name == "close_add_user") {
        this.add_user = 0;
      }
    }))

  }

  //1275
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) { //aspect ratio is 1280, 720
    setTimeout(() => {
      this.show_scroll = window.innerWidth < 1275
      this.mv_scr_left = window.innerWidth - 120
    })

  }
  ngAfterViewInit() {
    this.onResize()
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngOnInit(): void {
    this.initiTabs()
  }
  initiTabs() {
    let tab = localStorage.getItem('tab')
    if (!tab) {
      tab = 'contact'
    }
    this.select(tab)
  }

  select(tab: string) {
    if (this.bAccOverdue && tab != "billing" && tab != "payments") {
      this.pagesState.dispatch(new NotifyError("Account overdue"))
      return
    }
    this.tab = tab
    this.save_it = ""
    localStorage.setItem('tab', tab)
    setTimeout(() => {

      this.save_back = "#ECECEC"
      this.save_color = "#151515"
      let sel_index = 0
      if (this.tab == 'contact') {
        this.title = "Account Details"
        this.bShowSave = true;

      } else if (this.tab == 'calendar') {
        this.title = "Configure Calendar & Booking Page"
        this.bShowSave = false;
        sel_index = 1
      } else if (this.tab == 'links') {
        this.title = "Company & Personal Links"
        this.bShowSave = false;
        sel_index = 2
      } else if (this.tab == 'users') {
        this.title = ""
        this.bShowSave = false;
        sel_index = 3
      } else if (this.tab == 'custom') {
        this.title = "Account Preferences"
        this.bShowSave = true;
        sel_index = 4
      } else if (this.tab == 'billing') {
        this.title = "Plan & Billing History"
        this.bShowSave = false;
        sel_index = 5
      } else if (this.tab == 'api') {
        this.title = "API & Embedding Settings"
        this.bShowSave = true;
        sel_index = 6
      } else if (this.tab == 'payments') {
        this.title = "Payments"
        this.bShowSave = false;
        sel_index = 7
      }
      else if (this.tab == 'dealer') {
        this.title = "Reseller"
        this.bShowSave = false;
        sel_index = 8
      }
      let n_tabs: any = []
      for (let i = this.start_at_tab_index; i < this.orig_tabs.length; i++) {
        if (i < 4 || this.roll == 'admin') {
          if (this.orig_tabs[i].id != "api" || this.bHasAPI) {
            let t = Object.assign({}, this.orig_tabs[i])
            t.selected = i == sel_index
            n_tabs.push(t)
          }
        }
      }
      this.tabs = n_tabs
      this.last_tab_index = n_tabs.length - 1

      if (this.converge_acc != "keyzii") {
        let last_tab = this.tabs[this.last_tab_index]
        if (last_tab.id == "payments") {
          this.tabs.pop()
          this.last_tab_index = this.tabs.length - 1
        }
      }


    })
  }
  enableSave($event: boolean) {
    setTimeout(() => {
      if ($event) {
        this.save_back = "#3B5FE0"
        this.save_color = "#fff"
      } else {
        this.save_back = "#ECECEC"
        this.save_color = "#151515"
      }
    })
  }
  save() {
    this.save_it = this.tab + new Date().getTime()
  }
  //users
  filterName(val: string) {
    this.filter_name = val;
  }
  filterType(val: any) {
    this.filter_type = val
  }
  add_user: number = 0
  addUser() {
    this.add_user++
  }
  scrollLeft($event: any) {
    $event.stopPropagation()
    this.hdr_left -= 100;
  }
  scrollRight($event: any) {
    $event.stopPropagation()

    this.hdr_left += 100;
    if (this.hdr_left > 0) {
      this.hdr_left = 0
    }

  }
  billingDet() {
    this.pagesState.dispatch(new ShowCC("info"))
  }
  // showSearchCahnged(v: any) {
  //   this.bShowBillingSearch = v
  // }
  async saveMerchantId() {
    try {
      await this.pay_service.saveMerchantId(this.merchant_id)
      this.bEditMechantId = false;
    } catch (e) {
      console.error(e)
    }
  }

  addAccount() {
    this.pagesState.dispatch(new ShowCC("newacc"))

  }
}
